import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/openKitchen',
    name: 'openKitchen',
    component: () => import("../views/openKitchen.vue"),
  }
  ,{
    path: '/kitchenItem',
    name: 'kitchenItem',
    component: () => import("../views/kitchenItem.vue"),
  }
  ,{
    path: '/consumption',
    name: 'consumption',
    component: () => import("../views/consumption.vue"),
  }
]

const router = new VueRouter({
  routes
})

export default router
