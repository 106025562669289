<template>
  <div class="home" >
    <div class="title">
      <img src="../assets/img/切图 8.png" alt="" style="width: 174px; height: 145px;margin-right: 44px;">
      <div style="margin: auto 0 ;text-align: left;">
        <p style="margin-bottom: 15px;">智慧食堂管理系统</p>
        <p>Smart canteen management system</p>
      </div>
    </div>
    <a href="https://stcm.hbzhongzhi.cn" style="flex: 1 1 0px;  ">
   <div class="bj"  @click="clickitem(1)">
    <div class="overlay"></div>  
    <img src="../assets/img/切图 5 (1).png" alt="">
    <p class="bj-titlt">食堂采买系统</p>
   </div>
  </a>
   <div class="bj" @click="clickitem(2)">
    <div class="overlay"></div>  
    <img src="../assets/img/切图 6.png" alt="">
    <p class="bj-titlt">明厨亮灶</p>
   </div>
   <div class="bj" @click="clickitem(3)">
    <div class="overlay"></div>  
    <img src="../assets/img/切图 7.png" alt="">
    <p  class="bj-titlt">食堂消费</p>
   </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      
    }
  },
  methods: {
    clickitem(item) {
      if(item===1){

      }else if(item===2){
        this.$router.push('/openKitchen')
      }else if(item===3){
        this.$router.push('/consumption')
      }
    }
  },
}
</script>
<style lang="scss" scoped>
p{
  
  margin: 0;
  color: #fff;
font-size: 36px;
}
.title{
  z-index: 100;
  position: absolute;
  // top: 50px;
  // left: 550px;
  margin-top: 50px;
  letter-spacing: 2px;  
  display: flex;

}
.home {  
  display: flex;  
  flex-direction: row;  
  justify-content: center; 
  // align-items: space-between;  
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 800px;
  min-height: 800px;
}  
.bj>img{
  width: 100%;
height: 100%;
}
.bj-titlt{
position: absolute;
top: 50%;
left:35%;
}
body{
height: 100vh;

}
.bj {  
  cursor: pointer;
  position: relative;
  display: flex;
  // align-items: center;

  position: relative;  
  height: 100%;

  flex: 1 1 0px;  

}  
.overlay {  
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    height: 100%;  
    width: 100%;  
    opacity: 0.7; 
    transition: opacity 0.5s;  
    background-color: #000;  
}  
  
.bj:hover .overlay {  
  opacity: 0;  
   
}
</style>